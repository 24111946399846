/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Outfit:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap');


body p, a {
    font-family: "Outfit", sans-serif !important;
}

body p {
    color: #403b58;
}

.navbar li a {
    font-family: "Space Grotesk", sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin: 0px 15px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Space Grotesk", sans-serif;
}

.btn{
    font-family: "Space Grotesk", sans-serif !important;
}

.logo {
    width: 260px;
    height: auto;
  }

  .btn {
    --bs-border-radius: 0;
  }

  .clients {
    background-image: url("assets/images/map-bg.png");
  }

  .display-5 {
    font-weight: 600 !important;
    color: #2d7be2;
}

.display-6 {
    font-size: 1.5rem !important;
    line-height: 35px !important;
}

.callout {
    background-image: url("assets/images/box-bg.svg");
    background: linear-gradient(-45deg, rgba(44, 211, 162, 1), rgba(45, 137, 216, 0.8));
}

.btn-lg {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.25rem;
    --bs-btn-border-radius: 0px !important;
}

.display-4 {
    font-size: 3rem !important;
    font-weight: 500 !important;
}

hr {
    color: #c6c6c6 !important;
    width: 95%;
}

.accordion-button {
    font-weight: 600 !important;
    font-size: 1.1rem !important;
}

.display-3 {
   color: #ffffff;
   font-weight: 600 !important;
}

.slider {
    background: linear-gradient(-45deg, rgba(44, 211, 162, 1), rgba(45, 137, 216, 0.8));
    color: white;
    height: 100%;
}

.pageTitle {
    background: linear-gradient(-45deg, rgba(44, 211, 162, 1), rgba(45, 137, 216, 0.8));
    color: white;
    padding: 30px 0px;
    text-align: center;
}

.footer {
    background: #f5f5f5;
    color: rgb(23, 23, 23);
    background-image: url("assets/images/box-bg.svg");
}

.whychoseus .fa-solid {
    color: #505050;
    font-size: 5rem;
    margin: 30px 0px;
}